<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-simple-table v-if="returnedData.length" dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th> id</th>
                <th> Product</th>
                <th> Ordered Item</th>
                <th> Returned Item</th>
                <th> Return Type</th>
                <th> Reason</th>
                <th> Status</th>
                <th> Ordered At</th>
                <th> Returned At</th>
                <th> Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in returnedData" :key="`returned+${index}`">
                <td>{{ index + 1 }}</td>
                <td>
                  <img :alt="item.name" :src="item.image" style="max-width: 100px">
                  <p>{{ item.name }}</p>
                </td>
                <td>{{ item.ordered_qty }}</td>
                <td>{{ item.returned_qty }}</td>
                <td>{{ item.return_type }}</td>
                <td>{{ item.reason }}</td>
                <td>
                  <span v-html="statusBadge(item.status)"></span>
                </td>
                <td>{{ item.ordered_at | date }}</td>
                <td>{{ item.created_at | date }}</td>
                <td>
                  <v-btn color="primary">History</v-btn>
                </td>
              </tr>
              <!--          <tr v-for='(order, index) in orders' :key="'orders'+index">-->
              <!--            <td>{{ order.order_number }}</td>-->
              <!--            <td>{{ order.payment_method }}</td>-->
              <!--            <td v-html='statusBadge(order.payment_status)'></td>-->
              <!--            <td v-html='statusBadge(order.status)'></td>-->
              <!--            <td>{{ order.total | currency }}</td>-->
              <!--            <td> <v-btn color='primary' :to="{name: 'Invoice', params: {invoice : order.id }}">View Order</v-btn> </td>-->
              <!--          </tr>-->
              </tbody>
            </template>
          </v-simple-table>
          <v-card-text v-else class="d-inline-flex justify-space-around">
            <v-alert outlined type="info">
              You have not returned any order or product
            </v-alert>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getReturnedOrder } from '@/api/user';

export default {

  name: 'ReturnHistory',
  data: () => ({
    returnedData: [],
  }),

  methods: {
    async getReturnedOrder() {
      let response = await getReturnedOrder();
      this.returnedData = response.data.data;
    }

  },
  async mounted() {
    await this.getReturnedOrder();
  }
};
</script>

<style scoped>

</style>
